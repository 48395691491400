import React, { useState } from "react"
import axios from "axios"

/* Import Global Components */
import Page from "~components/page"
import Form from "~components/form/form"
import Input from "~components/form/input"
import PhoneNumber from "~components/form/phoneNumber"
import Checkbox from "~components/form/checkbox"
import Submit from "~components/form/submit"
import ThankYou from "~components/form/thankYou"
import FadeIn from "~components/animations/fadeInChildElements"
import FadeInWords from "~components/animations/fadeInWordsSequentially"

/* Import Local Styles */
import "./signup.css"

const Signup = () => {
  const [
    signUpDescriptionAnimationFinished,
    setSignUpDescriptionAnimationFinished,
  ] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const disableButton = () => {
    setCanSubmit(false)
  }

  const enableButton = () => {
    setCanSubmit(true)
  }

  const handleSubmit = (data, reset) => {
    const { email, emailUpdates, name, phone, phoneUpdates } = data
    // setIsSubmitting(true)
    axios
      .post("/api/subscribe", {
        email,
        emailUpdates,
        name,
        phone,
        phoneUpdates,
      })
      .then(() => {
        setSubmitted(true)
        reset()
      })
      .catch(function (error) {
        if (error.response.data.detail) {
          if (error.response.data.detail.includes("already a list member")) {
            console.log(`${email} is already subscribed to Puppy Love`)
          }
        } else if (error.response.data.message) {
          console.log(error.response.data.message)
        } else {
          console.log("Error signing up for Puppy Love Email List")
        }
      })
  }
  return (
    <Page className="signup">
      <div className="container">
        <div className="signup-description">
          <FadeInWords
            copy="Stay fresh with Puppy Love. We'll send about one email a month and one text a quarter."
            delay={100}
            transitionDuration={250}
            callback={() => {
              setSignUpDescriptionAnimationFinished(true)
            }}
          />
        </div>
        <div className="signup-form">
          <Form
            handleValidSubmit={handleSubmit}
            handleOnValid={enableButton}
            handleOnInvalid={disableButton}
          >
            <FadeIn
              delay={325}
              transitionDuration={520}
              start={signUpDescriptionAnimationFinished}
            >
              <Input placeholder="Name" name="name" validations="hasValues" />
              <Input
                type="email"
                placeholder="Email"
                name="email"
                validations="isEmail"
                required
              />
              <Checkbox
                value={true}
                name="emailUpdates"
                label="Send me updates 1x a month or so"
              />
              <PhoneNumber type="tel" placeholder="Phone" name="phone" />
              <Checkbox
                value={true}
                name="phoneUpdates"
                label="Send me texts every few months"
              />
              {!submitted ? (
                <Submit disabled={!canSubmit}>
                  <span>Sign Up</span>
                </Submit>
              ) : (
                <ThankYou>
                  <span>Thank You</span>
                </ThankYou>
              )}
            </FadeIn>
          </Form>
        </div>
      </div>
    </Page>
  )
}
export default Signup
